.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  width: 100%;
}
.flagBox {
  margin-top: 90px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.mainTitle {
  margin-top: 30px;
  text-align: center;
  font-size: var(--smallTitleTextSize);
  font-weight: 700;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 120px;
}
.linksBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.arcBox {
  max-width: 200px;
  width: 100%;
  overflow: hidden;
  height: 150px;
}
.arch_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.item p {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: 400;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .flagBox {
    margin-top: 100px;
  }
  .flag {
    height: 100%;
    width: 250px;
  }
  .mainTitle {
    margin-top: 25px;
    font-size: var(--middleTitleTextSize);
  }
  .linksBox {
    margin-top: 75px;
  }

  .item {
    font-size: 16px;
    font-weight: 700;
    max-width: 200px;
  }
  .arcBox {
    max-width: 200px;
    width: 100%;
    overflow: hidden;
    height: 250px;
  }
}
@media screen and (min-width: 1920px) {
  .flagBox {
    margin-bottom: 300px;
  }
  .linksBox {
    margin-top: 100px;
  }
  .mainTitle {
    margin-top: 25px;
    font-size: var(--bigTitleTextSize);
  }
}
