.sliderBody {
  padding: 0 15px;
  margin-top: 70px;
}
.projectName {
  margin-bottom: 10px;
  text-align: center;
  font-size: var(--smallSubTitleTextSize);
}
.projectAuthor {
  margin-bottom: 10px;
  text-align: center;
  font-size: var(--smallTitleTextSize);
}
.projectText {
  margin: 10px 15px;
  white-space: pre-wrap;
  font-size: var(--mainSmallTextSize);
}
.photo {
  float: left;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  height: auto;
  max-height: 75%;

  object-fit: contain;
}
@media screen and (min-width: 1200px) {
  .sliderBody {
    padding-left: 250px;
    padding-right: 250px;
    margin-top: 100px;
  }
  .projectName {
    margin-bottom: 20px;
    font-size: var(--middleSubTitleTextSize);
  }
  .projectText {
    font-size: var(--mainMiddleTextSize);
  }
  .swiper-slide img {
    width: 100%;
    max-height: 50%;
    object-fit: cover;
  }
  .projectAuthor {
    font-size: var(--middleTitleTextSize);
  }
}
@media screen and (min-width: 1920px) {
  .projectText {
    font-size: var(--mainBigTextSize);
  }
  .projectName {
    font-size: var(--bigSubTitleTextSize);
  }
  .projectAuthor {
    font-size: var(--bigTitleTextSize);
  }
  .sliderBody {
    margin-top: 120px;
  }
}
