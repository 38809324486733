.archName {
  margin-top: 70px;
  text-align: center;
  font-size: var(--smallTitleTextSize);
}
.arch_img {
  margin-top: 20px;
}
.biographi {
  margin-top: 10px;
}

.archSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}

.projectListName {
  margin-top: 15px;
  padding-left: 30px;
}
.projectNameItem {
  line-height: 25px;
  text-decoration: underline;
}
.projectListBox {
  padding-top: 10px;
  padding-left: 30px;
}
@media screen and (min-width: 1200px) {
  .archName {
    font-size: var(--middleTitleTextSize);
    margin-top: 85px;
  }
  .biographi {
    margin-top: 20px;
  }
  .projectName {
    font-size: 30px;
    line-height: 50px;
  }
  .archSection {
    padding-left: 100px;
    padding-right: 100px;
  }

  .projectListName {
    padding-left: 0;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .biographi {
    font-size: 14px;
    line-height: 1.3;
  }
  .projectListBox {
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .projectList {
    list-style-position: inside;
    text-align: start;
    column-count: 2;
    list-style-type: none;
    max-width: 550px;
    width: 100%;
  }
  .projectNameItem {
    line-height: 35px;
    font-size: 16px;
    text-decoration: underline;
  }
}
@media screen and (min-width: 1920px) {
  .biographi {
    font-size: 24px;
  }
  .projectNameItem {
    font-size: 24px;
  }
  .projectListName {
    font-size: 28px;
  }
  .archName {
    font-size: var(--bigTitleTextSize);
  }
}
