#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  float: left;
  width: 100px;
  height: auto;
  object-fit: cover;
}
.swiper-pagination {
  position: relative;
  margin-top: 20px;
}
.swiper-pagination-bullet-active {
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 1200px) {
  .swiper-slide img {
    width: 100%;
    height: 400px;
  }
  .swiper {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1920px) {
  .swiper-slide img {
    width: 100%;
    height: 900px;
  }
  .swiper {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
