@import-normalize; /* bring in normalize.css styles */
@import '././variables/variables.css';

* {
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white;
  min-height: 100vh;
  font-size: var(--mainSmallTextSize);
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
a:hover {
  color: red;
  font-weight: 700;
  transition: all 0.25s ease-in 0s;
}
.container {
  margin: 0 auto;
  padding: 0 10px;

  width: 100%;
}
/* p {
  text-indent: 15px;
} */
@media screen and (min-width: 320px) {
  .container {
    max-width: 320px;
  }
}
@media screen and (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  body {
    font-size: var(--mainMiddleTextSize);
  }
  .container {
    max-width: 1200px;
  }
}
@media screen and (min-width: 1920px) {
  body {
    font-size: var(--mainBigTextSize);
  }
  .container {
    max-width: 1500px;
  }
}
